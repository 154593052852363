const loadMapData = async () => {
    let mapData = [];

    let builders = await fetch('https://bimaire-clayton-map.s3-us-west-1.amazonaws.com/json/builders.json');
    builders = await builders.json();

    let states = await fetch('https://bimaire-clayton-map.s3-us-west-1.amazonaws.com/json/states.json');
    states = await states.json();

    let builder_state = await fetch('https://bimaire-clayton-map.s3-us-west-1.amazonaws.com/json/builder_state.json');
    builder_state = await builder_state.json();

    builders.forEach((builder) => {
        let builder_statesForBuilder = builder_state.filter(bs => bs.builder_id === builder.id).map((bs) => {return bs.state_id});
        let statesForBuilder = states.filter(state => builder_statesForBuilder.includes(state.id));
        builder.states = statesForBuilder;
        mapData.push(builder);
    });

    return mapData;
}

export  {loadMapData};
import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';

const MapLoader = ({ allStates, stateData, activeBuilder, onClickChangeState }) => {
    /**
    * this function should read all the states from the 'allStates' Array
    * and change their fill color to gray
    */
  const usProxies = () => {
    return allStates.map(data => 
      <SvgProxy
        selector={`#${data.name}`}
        fill="#d3d3d3" />
    )
  }

   /**
   * read all states from the stateData array and change their fill to Green
   */
  const listProxies = () => {
    let activeBuilderStates;
    if (activeBuilder) {
      activeBuilderStates = activeBuilder.states.map(state => state.state_fullname);
    }
    return stateData.map(data => 
      <SvgProxy
        selector={`#${data.name}`} 
        fill={activeBuilder && !activeBuilderStates.includes(data.name) ? '#DAE8C0' : '#A4CB5C'}
        onClick={() => onClickChangeState(data)} 
      />
    ) 
  }


    return(
      <SvgLoader path={`https://bimaire-clayton-map.s3-us-west-1.amazonaws.com/USMap.svg`}
        className="USAMap">
        {stateData && usProxies()}
        {stateData && listProxies()}
      </SvgLoader>)
}

export default MapLoader;
import React from 'react';
import './Footer.css';

//functional component

//const Footer = (props) => {
const Footer = ({ mapData, setActiveBuilder }) => {   
  const listBuilderIcons = () => {
    return mapData.map(builder => {
      return(
        <img 
          key={builder.id} className='builder-icon'
          src={`https://bimaire-clayton-map.s3-us-west-1.amazonaws.com/${builder.builder_logo}`}
          alt={builder.builder_name}
          onClick= {() => setActiveBuilder(builder)} 
        />
      );
    })
  }
    return (
    <footer> { mapData && listBuilderIcons() }></footer>
    )
}


export default Footer;
import React from 'react';

const BuilderCards = ({ activeStateBuilders }) => {

  const listBuilderCards = () => {
    return activeStateBuilders.map(kittens=> {
      return(
        <div 
          key={kittens.id}
          className="builderCard"
         >
          <p className="builderCardInfo">{kittens.builder_description}</p>
          <p className="builderCardTitle">{kittens.builder_name}
          <a className="cardLink" href={kittens.builder_website}>visit website</a></p>
          <img 
          key={kittens.id} className='builderCardImage'
          src={`https://bimaire-clayton-map.s3-us-west-1.amazonaws.com/${kittens.builder_logo}`}
          alt="builder"
        />
        </div>
      );
    })
  }

  return (
  <div className="builderCardContainer">
    { activeStateBuilders && listBuilderCards() }
  </div>
  )
}

export default BuilderCards;

import React, { Component } from 'react';
import './App.css';
import axios from 'axios'; //download axios with "npm install axios"
import fakeMapData from './fakeMapData';
import {loadMapData} from './MapDataLoader';
import fakeMapImage from '../../images/USMap.svg';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import Footer from "../Footer/Footer";
import MapLoader from "../MapLoader/MapLoader";
import BuilderCards from "../BuilderCards/BuilderCards";

class App extends Component {
  constructor () {
    super();

      this.state = {
        mapData: null,
        showLogoInfo: false,
        activeBuilder: null,
        activeState: null,
        activeStateBuilders: null,
        stateData: null,
        allStates: ["New_York", "Oklahoma", "New_Mexico", "Colorado", "Texas", "Vermont",
          "New_Hampshire", "Rhode_Island", "Connecticut", "Massachusetts", "Pennsylvania",
          "New_Jersey", "Delaware", "West_Virginia", "North_Carolina", "South_Carolina",
          "Florida", "Mississippi", "Alabama", "Georgia", "Tennessee", "Kentucky", "Ohio",
          "Wisconsin", "Indiana", "Illinois", "Kansas", "Nebraska", "South_Dakota", "North_Dakota",
          "Minnesota", "Iowa", "Missouri", "Louisiana", "Arkansas", "Montana", "Wyoming", "Utah", "Arizona",
          "Idaho", "Nevada", "Washington", "Oregon", "Virginia", "California", "Maryland", "Michigan",
          "Maine"],
        overlayActive: false,

      };
    }
  
  componentDidMount() {
    this.getMapData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mapData !== this.state.mapData){
       this.makeUSProxies();
    }
  }

  getMapData = () => {

    loadMapData().then(mapData => {
      this.setState({mapData: mapData}, () => {
        this.dataObjectBuilder();
      });
    });
  }

  /**
   * sets the activeBuilder state
   * 
   * param
   *  builder: the builder from the map data array
   */
  setActiveBuilder = (builder) => {
    if (this.state.activeBuilder === builder) {
      this.setState({activeBuilder: null});
    } else {
      this.setState({activeBuilder: builder});
    }
  }


  clickNullBuilder = () => {
    this.setState({activeBuilder: null});
  }

 
  /**
   * this function creates an array of state objects
   * with associated builders to 
   * 
   * params 
   *  none
   * returns
   *  data: an object that 
   */
  dataObjectBuilder = () => {
    const states = [];
    //creates an array of state's names from all builders
    this.state.mapData.forEach(builder => {
      builder.states.forEach(state => {
        if (!states.includes(state.state_fullname)) {
          states.push(state.state_fullname);
        }
      })
    })

    const stateData = states.map(state => {
      const data = {};
      data.name = state.includes(' ') ? state.replace(' ', '_') : state;
      data.builders = [];

      this.state.mapData.forEach(builder => {
        const builderStates = builder.states.map(builderState => builderState.state_fullname);
        if (builderStates.includes(state)) {
          data.builders.push(builder);
        }
      })
    return data;
    })

  this.setState({stateData: stateData});
}


  /**
   * turns the array of US States into objects
   */
  makeUSProxies = () => {
    const allStates = this.state.allStates.map(individualState => {
      const data = {};
      data.name = individualState;
      return data;
    })
  this.setState({allStates: allStates});
  }
  

  onClickChangeState = (datum) => {
    this.setState(prevState => ({overlayActive: !prevState.overlayActive}));
    this.setState({activeState: datum.name});
    this.setState({activeStateBuilders: datum.builders});

  }
  
    
  render() { 
    
      return (
      
      <div className="App">
        {this.state.overlayActive && 
          <div 
          className="overlay" 
          onClick={this.onClickChangeState}>
            {this.state.activeState &&
            <SvgLoader 
              className="stateSvg"
              path={`https://bimaire-clayton-map.s3-us-west-1.amazonaws.com/${this.state.activeState}_pop.svg`}>
            </SvgLoader>
            }

            <BuilderCards
              activeStateBuilders={this.state.activeStateBuilders}
            />

          </div>
        }
          <div className="title">
            <h1> Clayton Properties Group&trade; Markets </h1>
          </div>
          {this.state.activeBuilder &&
          <div className="overlayInfo"
              onClick={this.clickNullBuilder}>
            <div className='info'>
              <img src={`https://bimaire-clayton-map.s3-us-west-1.amazonaws.com/${this.state.activeBuilder.builder_logo}`} className="builder_logo_title"/>
              <p className="builderInfoName">{this.state.activeBuilder.builder_name} 
              <a className="builderLink" href={this.state.activeBuilder.builder_website}>visit website</a></p>
              <p className="builderInfoDesc">{this.state.activeBuilder.builder_description}
              </p>
            </div>
          </div>}
       
        <MapLoader
          allStates={ this.state.allStates }
          stateData={ this.state.stateData }
          activeBuilder={this.state.activeBuilder}
          onClickChangeState={this.onClickChangeState}
        />
        
      
        <Footer 
          mapData={ this.state.mapData } 
          setActiveBuilder={ this.setActiveBuilder }
        />
        
      
      </div>
    ); 
  }
  
}

export default App;
